.consultation-popup {
  padding: 31px 24px;
  width: 80vw;

  @include md() {
    width: 40vw;
  }

  .main-input-wrapper:first-child {
    margin-top: 0;
  }

  .main-input-wrapper {
    margin-top: 21px;
  }

  .main-button {
    width: 100%;
  }
}

.course-popup {
  width: 85vw;
  height: 70vh;
  border-radius: 30px;
  overflow-y: scroll;

  @include md() {
    height: 95vh;
    overflow-y: auto;
  }

  .our-course-whom__title {
    margin-left: 0;
  }

  .course-popup-header {
    position: relative;
    background: $brandColorDark;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 25px 35px;

    @include md() {
      padding: 55px 70px;
    }
  }

  .course-popup-header__icon {
    display: none;

    @include md() {
      position: absolute;
      top: -25%;
      right: 5%;
    }
  }

  .course-popup-body {
    box-sizing: border-box;
    padding: 25px 35px;

    @include md() {
      padding: 55px 70px;
    }
  }

  .course-popup-detailed {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @include md() {
      margin-top: 31px;
    }
  }

  .course-popup-detailed-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    @include md() {
      width: 48%;
      margin-top: 0;
    }
  }

  .course-popup-detailed-block-program {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include md() {
      width: 70%;
    }
  }

  .course-popup-detailed-block-icon {
    width: 70px;
  }

  .course-popup-detailed-block-desc {
    width: calc(100% - 90px);
  }

  .course-popup-detailed-block__title {
    @include font($brandColor, 18px, 24px);
    margin: 0;
  }

  .course-popup-detailed-block__documents {
    @include font($mainTextColor, 18px, 24px);
    margin: 0;
    font-weight: 500;
  }

  .course-popup-detailed-block-descriptions {
    margin-top: 5px
  };

  .course-popup-detailed-block__description {
    @include font($mainTextColor, 16px, 24px);
    margin: 0;
  }

  .course-popup-detailed-block__price {
    @include font($mainTextColor, 18px, 24px);
    margin: 5px 0 0;
    font-weight: bold;
  }
}

.feedback-popup {
  width: 85vw;
  padding-bottom: 35px;

  @include md() {
    width: 60vw;
  }

  .feedback-popup-header {
    position: relative;
    background: $brandColor;
    padding: 55px 70px;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .feedback-popup__title {
    @include font(white, 20px, 24px);
    margin: 0;
  }

  .feedback-popup-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 55px 36px 0 36px;
  }

  .feedback-popup-body__form {
    width: 100%;

    @include md() {
      width: 70%;
    }

    .main-input-wrapper:first-child {
      margin-top: 0;
    }

    .main-input-wrapper {
      margin-top: 33px;
    }
  }

  .feedback-popup-body__photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include md() {
      width: 30%;
    }
  }

  .feedback-popup-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feedback-popup-body-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 135px;
    height: 129px;
    border: 1px solid #CBCBCB;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 8px;
    cursor: pointer;
  }

  .feedback-popup-body-upload__plus {
    font-weight: 600;
    font-size: 2em;
    color: #C4C4C4;
  }

  .feedback-popup-body-upload__text {
    @include font(#C4C4C4, 16px, 20px);
    margin: 8px 0 0;
    text-align: center;
    font-weight: 500;
  }

  .feedback-popup-body-rating {
    margin-top: 24px;

    .feedback-popup-body-rating__icon:first-child {
      margin-left: 0;
    }
  }

  .feedback-popup-body-rating__icon {
    color: #DBDBDB;
    margin-left: 14px;
    font-size: 1.2em;
    cursor: pointer;
  }

  .feedback-popup-body-rating__active {
    color: $brandColorLight;
  }
}

.lecturer-popup {
  width: 85vw;
  padding: 0 15px 25px 15px;
  box-sizing: border-box;

  @include sm() {
    padding: 0 35px 25px 35px;
  }

  @include md() {
    padding: 0 80px 80px 80px;
  }

  .lecturer__icon {
    margin-top: -54px;
  }

  .lecturer-course {
    @include md() {
      @include font(white, 14px, 16px);
      text-align: left;
    }

    @include md-high() {
      @include font(white, 16px, 20px);
    }
  }

  .lecturer-courses {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
    width: 100%;

    @include md() {
      width: 59%;
      justify-content: flex-start;
    }

    .lecturer-course:first-child {
      margin-left: 0;
    }

    .lecturer-course {
      margin: 12px 0 0;
    }
  }

  .lecturer__education {
    text-align: left;
  }

  .lecturer-popup-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @include md() {
      width: 50%;
    }
  }

  .lecturer-popup-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .lecturer-popup-row_center {
    justify-content: center;
  }

  .lecturer-popup-block__hobbies-title {
    @include font($brandColor, 18px, 22px);
    margin:  20px 0 0;
  }

  .lecturer-popup-block__hobbies-text {
    @include font($mainTextColor, 16px, 20px);
    margin: 27px 0 0;
  }
}

.quiz-popup {
  width: 85vw;
  box-sizing: border-box;

  .quiz-popup-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background: $brandColorLight;
    border-radius: 30px;
    padding: 25px 35px 25px 35px;

    @include md() {
      padding: 80px 55px;
    }
  }

  .quiz-popup__title {
    @include font(white, 36px, 44px);
    margin: 0;
    width: 100%;
    text-align: center;

    @include md() {
      width: calc(100% - 110px);
      text-align: left;
    }
  }

  .quiz-popup__icon {
    display: none;

    @include md() {
      display: block;
      position: absolute;
      top: -16%;
      right: 5%;
    }
  }

  .quiz-popup-body {
    padding: 25px 35px 25px 35px;

    @include md() {
      padding: 80px 55px;
    }
  }

  .quiz-popup-body__question {
    @include font($brandColor, 24px, 29px);
    margin: 0;
    text-align: center;
    font-weight: 500;
  }

  .quiz-popup__answer {
    margin-top: 18px;
  }

  .quiz-popup-body-content {}

  .quiz-popup-body-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 56px;

    @include sm() {
      flex-direction: row;
    }

    .main-button, .main-button-white {
      margin-top: 0;
      padding: 12px 55px;
    }

    @include sm() {
      .main-button {
        margin: 0 0 0 29px;
      }
    }

    .main-button-white {
      margin: 20px 0 0;

      @include sm() {
        margin: 0 0 0 29px;
      }
    }
  }

  .quiz-popup-body-course {
    text-align: center;

    .main-button {
      margin: 42px 0 0;
    }
  }

  .quiz-popup-body-course__title {
    @include font($brandColor, 20px, 24px);
    margin: 19px 0 0;
    text-transform: uppercase;

    @include sm() {
      @include font($brandColor, 24px, 28px);
    }

    @include md() {
      @include font($brandColor, 64px, 78px);
    }
  }

  .quiz-popup-body-course__about {
    @include font($buttonColor, 16px, 20px);
    margin: 21px 0 0;
    text-decoration: underline;
    cursor: pointer;
  }
}

.success-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-block__icon {
  color: #77DD00;
}

.success-block__title {
  @include font($mainTextColor, 16px, 20px);
  font-weight: bold;
  margin: 10px 0 0;
}

.success-block__text {
  @include font($mainTextColor, 16px, 20px);
  margin: 10px 0 0;
  text-align: center;
}
