.global-portal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;

  &:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: " ";
  }
}

.global-portal__container {
  display: inline-block;
  max-width: calc(100% - 30px);
  text-align: left;
  vertical-align: middle;
  background-color: white;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
  transform: scale(0.9);
  transition: all 0.3s ease;
  border-radius: 30px;
}

.global-portal__close {
  position: absolute;
  top: -35px;
  right: -35px;
  cursor: pointer;
  color: white;
}
