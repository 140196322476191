body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

.main-button {
  @include font(white, 16px, 20px);
  background: $buttonColor;
  border-radius: 60px;
  border: none;
  margin-top: 48px;
  padding: 18px 33px;
  cursor: pointer;
  transition: background 0.2s;
  outline: none;

  &:hover {
    background: $buttonColorHover;
  }
}

.main-button-white {
  @include font($buttonColor, 16px, 20px);
  background: white;
  border-radius: 60px;
  margin-top: 49px;
  padding: 18px 20px;
  cursor: pointer;
  transition: background 0.2s;
  outline: none;
  border: 1px solid $buttonColor;

  &:hover {
    color: white;
    background: $buttonColor;
  }
}

.main-input-wrapper {
  position: relative;
}

.main-input {
  @include font($brandColorDark, 16px, 20px);
  width: 100%;
  background: #FFFFFF;
  border-radius: 30px;
  outline: none;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #C4C4C4;

  &::placeholder {
    @include font(#C4C4C4, 16px, 20px);
  }
}

.main-input-has-error {
  .main-input {
    border-color: $buttonColor;
  }

  .main-textarea {
    border-color: $buttonColor;
  }
}

.main-input-error {
  @include font(white, 11px, 13px);
  position: absolute;
  bottom: calc(100% - 12px);
  right: 20px;
  padding: 5px 10px;
  background: #FF3535;
  border-radius: 30px;
}

.main-textarea {
  @include font($brandColorDark, 16px, 20px);
  width: 100%;
  background: #FFFFFF;
  border-radius: 30px;
  outline: none;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #C4C4C4;
  resize: vertical;
  min-height: 129px;

  &::placeholder {
    @include font(#C4C4C4, 16px, 20px);
  }
}

.burger-menu {
  display: block;
  cursor: pointer;

  @include md() {
    display: none;
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0 0;
  }

  span:nth-last-child(2) {
    transform-origin: 0 100%;
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(-45deg) translate(-2px, 3px);
    }

    span:nth-last-child(2) {
      transform: rotate(45deg) translate(-5px, -7px);
    }

    span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
  }
}

.burger-list {
  position: fixed;
  top: 107px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
}

.burger-list__link {
  @include font($mainTextColor, 24px, 72px);
  text-decoration: none;
  font-weight: bold;

  &.active {
    color: $buttonColor;
  }

  &:hover {
    color: $buttonColor;
  }
}

.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &+label {
    display: inline-flex;
    align-items: center;
    user-select: none;

    &:before {
      content: '';
      display: inline-block;
      width: 47px;
      height: 47px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $brandColor;
      border-radius: 50%;
      margin-right: 0.5em;
      background-size: 50% 50%;
      background: white no-repeat center center;
    }
  }

  &:not(:disabled) {
    &:not(:checked)+label:hover::before {
      border-color: #b3d7ff;
    }

    &:active+label::before {
      background-color: #b3d7ff;
      border-color: #b3d7ff;
    }
  }

  &:focus {
    &+label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:not(:checked)+label::before {
      border-color: #80bdff;
    }
  }

  &:checked+label::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 25 25' %3E%3Ccircle cx='12.5' cy='12.5' r='12.5' fill='%2373C9FF'%3E%3C/circle%3E%3C/svg%3E");
  }
}

.error-message {
  color: $buttonColor;
}
