.reviews {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 18px;
}

.review {
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-top: 22px;

  @include md() {
    margin-top: 41px;
    width: 49%;
  }
}

.review-header {
  position: relative;
  background: $brandColor;
  padding: 19px 17px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  @include sm() {
    flex-wrap: nowrap;
    padding: 19px 18px 19px 27px;
  }

  @include md() {
    padding: 19px 37px;
  }
}

.review-header__name {
  @include font(white, 20px, 24px);
  margin: 0 0 0 28px;
  text-align: left;
  width: calc(100% - 55px - 28px);

  @include sm() {
    width: calc(100% - 55px - 154px);
  }
}

.review-header-rating {
  width: 100%;
  margin: 10px 0;
  text-align: center;

  @include sm() {
    width: 126px;
    margin: 0;
    text-align: left;
  }

  .review-header__icon:first-child {
    margin-left: 0;
  }
}

.review-header__icon {
  color: #EFF6F9;
  font-size: 1.1em;
  margin-left: 10px;
}

.review-header__icon_active {
  color: #F3CC30;
}

.review-footer {
  margin-top: 25px;
  padding: 2px 30px 58px;
  box-sizing: border-box;

  @include md() {
    padding: 2px 30px 42px;
  }
}

.review-footer__text {
  @include font($mainTextColor, 16px, 20px);
  margin: 0;
}

.review-footer__read-more {
  @include font($brandColor, 16px, 20px);
  margin: 0;
  cursor: pointer;
}

.reviews-buttons {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;

  .main-button {
    padding: 10px 4px;
    margin: 0;

    @include sm() {
      padding: 15px 45px;
    }
  }

  .main-button-white {
    padding: 10px 4px;
    margin: 0 0 0 15px;

    @include sm() {
      padding: 15px 45px;
      margin: 0 0 0 45px;
    }
  }
}
