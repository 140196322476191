.why-we-are-wrapper {

  @include md-high() {
    .container-page__title {
      display: none;
    }
  }
}

.why-we-are {
  overflow: visible;
  padding: 20px 0;
  box-sizing: border-box;

  @include md-high() {
    overflow: hidden;

    .why-we-are-row:first-child {
      margin-top: 115px;
    }
  }
}

.why-we-are-plus-descriptions {
  margin-top: 20px;

  @include md-high() {
    width: calc(100% - 100px);
  }
}

.why-we-are-plus {
  position: relative;
  width: 100%;
  background: #ECF8FF;
  border-radius: 30px;
  padding: 10px 26px;
  box-sizing: border-box;
  margin-top: 20px;

  @include md() {
    width: 450px;
    margin-left: 32px;
    margin-top: 0;
  }

  @include md-high() {
    padding: 26px 22px;
  }
}

.why-we-are-plus__title {
  @include font($brandColor, 18px, 22px);
  margin: 0;

  @include md-high() {
    width: calc(100% - 100px);
  }
}

.why-we-are-plus__description {
  @include font($mainTextColor, 16px, 20px);
  margin: 0 0 0;
  display: inline-block;

  @include md() {
    display: block;
  }
}

.why-we-are-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include md() {
    flex-wrap: nowrap;
    margin-top: 41px;
  }

  .why-we-are-plus:first-child {
    margin-left: 0;
  }
}

.why-we-are-row-main {
  display: none;

  @include md-high() {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  }
}

.why-we-are-plus-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.why-we-are-plus__icon {
  position: relative;

  @include md-high() {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.why-we-are-row__main-title {
  @include font($brandColorDark, 42px, 51px);
  margin: 0;
  text-align: center;
}
