.custom-select-wrapper {
  width: 100%;
}

.custom-select {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: none;
  background: inherit;
  width: 100%;
  outline: none;
  padding: 0;

  @include md() {
    justify-content: flex-end;
  }
}

.custom-select__marker {
  color: $brandColorLight;
}

.custom-select__icon {
  color: $brandColor;
  margin-left: 5px;
}

.custom-select__text {
  @include font($brandColor, 16px, 18px);
  font-weight: 700;
  margin: 0 0 0 5px;
}

.custom-select__phone {
  display: inline-block;
  @include font($mainTextColor, 16px, 20px);
  margin: 12px 0 0;
  width: 100%;
  text-align: center;

  @include md() {
    display: none;
  }
}

.toggle-list {
  background: #fff;
  z-index: 2;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  padding: 14px 23px;
  margin: 0;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

  .toggle-list__item:first-child {
    margin-top: 0;
  }
}

.toggle-list__item {
  display: flex;
  margin-top: 10px;

  &:hover .custom-select__text {
    color: $buttonColor;
  }
}
