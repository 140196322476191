.navbar {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: $brandColor;
  list-style: none;
  padding: 15px 13.3%;
  margin: 0;
  box-sizing: border-box;

  .navbar__li:first-child {
    margin-left: 0;
  }

  @include md() {
    display: flex;
  }
}

.navbar__li {
  margin-left: 75px;
}

.navbar__link {
  @include font(#fff, 16px, 20px);
  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;

  &.active {
    color: $brandColorLight;
  }

  &:hover {
    color: $brandColorLight;
  }
}
