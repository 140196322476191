.consultation {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 19px auto 0;
  padding: 32px 13.3% 44px 13.3%;

  @include md() {
    width: 73.4%;
    border-radius: 30px;
  }

  @include md-high() {
    flex-direction: row;
    margin: 106px auto 30px;
    padding: 82px 6% 70px 6%;
  }

  &.light {
    background: $brandColorLight;

    .consultation-desc__title-sub {
      @include font($brandColorDark, 22px, 24px);

      @include sm() {
        @include font($brandColorDark, 36px, 44px);
      }

      @include md() {
        @include font($brandColorDark, 40px, 52px);
      }

      @include lg() {
        @include font($brandColorDark, 50px, 61px);
      }
    }
  }

  &.dark {
    background: $brandColorDark;

    .consultation-desc__title-sub {
      @include font($buttonColor, 22px, 24px);

      @include sm() {
        @include font($buttonColor, 36px, 44px);
      }

      @include md() {
        @include font($buttonColor, 40px, 52px);
      }

      @include lg() {
        @include font($buttonColor, 50px, 61px);
      }
    }
  }

  .main-input-wrapper:first-child {
    margin-top: 0;
  }

  .main-input-wrapper {
    margin-top: 17px;
  }

  .main-button {
    width: 100%;
    margin-top: 20px;
  }
}

.consultation-desc {
  width: 100%;
  text-align: center;

  @include md-high() {
    width: 65%;
    text-align: left;
  }
}

.consultation-form {
  width: 79%;
  margin: 32px auto 0;

  @include md-high() {
    width: 31%;
    margin: 0;
  }
}

.consultation-desc__title {
  @include font(white, 22px, 24px);
  margin: 0;

  @include sm() {
    @include font(white, 36px, 44px);
  }

  @include lg() {
    @include font(white, 50px, 61px);
  }
}

.consultation-desc__title-sub {
  margin: 0;
}
