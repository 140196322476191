.banner {
  position: relative;
  padding-bottom: 10px;

  .main-button {
    @include font(white, 11px, 14px);
    margin-top: 23px;
    font-weight: bold;

    @include sm() {
      @include font(white, 13px, 18px);
    }

    @include md() {
      @include font(white, 16px, 20px);
      font-weight: normal;
    }
  }

  @include sm() {
    .main-button {
      padding: 21px 33px;
      width: 41%;
    }
  }

  @include md() {
    .main-button {
      width: auto;
      margin-top: 48px;
      padding: 18px 33px;
    }
  }
}

.banner-image {
  width: 100%;
  display: none;

  @include md() {
    display: block;
  }
}

.banner-image-mobile {
  display: block;
  width: 100%;
  height: 400px;
  background-image: url("../../images/background-mobile.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 70%;

  @include md() {
    display: none;
  }
}

.banner-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;

  @include md() {
    left: 13.3%;
    width: 50%;
    text-align: left;
    transform: translateY(-50%);
  }
}

.banner__title {
  @include font(white, 28px, 34px);
  margin: 10px 0 0;
  text-transform: uppercase;

  br:nth-child(2) {
    display: none;
  }

  @include sm() {
    margin: 37px 0 0;
  }

  @include md() {
    @include font($brandColor, 32px, 44px);

    br:nth-child(2) {
      display: block;
    }
  }

  @include md-high() {
    @include font($brandColor, 40px, 52px);
  }

  @include lg() {
    @include font($brandColor, 64px, 78px);
    margin: 119px 0 0;
  }
}

.banner__description {
  @include font(white, 18px, 22px);
  margin: 13px auto 0;

  @include sm() {
    width: 55%;
    text-align: center;
  }

  @include md() {
    @include font($mainTextColor, 16px, 18px);
    width: 75%;
    text-align: left;
    margin: 13px 0 0;
  }

  @include md-high() {
    @include font($mainTextColor, 20px, 24px);
    width: 100%;
  }

  @include lg() {
    @include font($mainTextColor, 24px, 29px);
  }
}
