.lecturers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;

  @include lg() {
    margin-top: 152px;
  }
}

.lecturer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 0 10px 57px;
  box-sizing: border-box;
  margin-top: 127px;

  @include md() {
    width: 48%;
    padding: 0 10px 69px;
  }

  @include lg() {
    width: 23%;
    margin-top: 0;
  }
}

.lecturer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.lecturer__icon {
  margin-top: -99px;
  border-radius: 50%;
  width: 192px;
  height: 195px;

  @include md() {
    width: 166px;
    height: 168px;
    margin-top: -85px;
  }
}

.lecturer__name-block {
  margin: 46px 0 0;
  text-align: center;

  @include md() {
    margin: 60px 0 0;
  }
}

.lecturer__name {
  @include font($mainTextColor, 24px, 29px);
  margin: 0 0 0;
}

.lecturer__text {
  @include font(#A2A2A2, 18px, 22px);
  margin: 24px 0 0;
  text-align: center;

  @include md() {
    margin: 27px 0 0;
  }
}

.lecturer__experience {
  @include font($buttonColor, 50px, 61px);
  margin: 0;
  font-weight: bold;
  margin-top: 10px;

  @include md() {
    margin-top: 0;
  }
}

.lecturer__education {
  @include font($brandColor, 24px, 29px);
  width: 100%;
  margin: 12px 0 0;
  font-weight: bold;
  text-align: center;

  @include md() {
    margin: 18px 0 0;
  }
}

.lecturer-courses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 27px auto 0;
  width: 90%;

  @include md() {
    width: 100%;
    margin: 27px 0 0;
  }

  .lecturer-course:first-child {
    margin-top: 0;
  }
}

.lecturer-course {
  @include font(white, 16px, 20px);
  background: $brandColorLight;
  border-radius: 60px;
  font-weight: bold;
  padding: 6px 25px;
  box-sizing: border-box;
  margin-top: 19px;
  text-align: center;
  max-width: 100%;
  word-break: break-word;

  @include sm() {
    @include font(white, 18px, 22px);
  }

  @include md() {
    margin-top: 13px;
  }
}

.lecturer__more-info {
  @include font($brandColorDark, 18px, 22px);
  text-decoration-line: underline;
  font-weight: 500;
  margin: 45px 0 0;
  cursor: pointer;

  @include md() {
    margin: 52px 0 0;
  }
}
