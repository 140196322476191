.global-preloader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background: $brandColorDark;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);
}

.loading-spinner div {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 4px solid #1c8b9b;
  border-color: #1c8b9b transparent transparent transparent;
  border-radius: 50%;
  animation: loading-spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading-spinner-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
