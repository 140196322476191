.online-courses {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  text-decoration: none;

  @include md() {
    margin-top: 83px;
  }
}

.online-courses__title {
  @include font($brandColor, 17px, 20px);
  margin: 0 10px 0 0;
  font-weight: 500;
  width: calc(100% - 92px);
  text-align: center;

  @include sm() {
    @include font($brandColor, 24px, 29px);
    margin: 0 30px 0 0;
    width: auto;
  }

  @include md() {
    @include font($brandColor, 42px, 51px);
  }
}
