@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('MontserratBold/MontserratBold.eot');
  src: url('MontserratBold/MontserratBold.eot') format('embedded-opentype'),
  url('MontserratBold/MontserratBold.woff2') format('woff2'),
  url('MontserratBold/MontserratBold.woff') format('woff'),
  url('MontserratBold/MontserratBold.ttf') format('truetype'),
  url('MontserratBold/MontserratBold.svg#MontserratBold') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('MontserratMedium/MontserratMedium.eot');
  src: url('MontserratMedium/MontserratMedium.eot') format('embedded-opentype'),
  url('MontserratMedium/MontserratMedium.woff2') format('woff2'),
  url('MontserratMedium/MontserratMedium.woff') format('woff'),
  url('MontserratMedium/MontserratMedium.ttf') format('truetype'),
  url('MontserratMedium/MontserratMedium.svg#MontserratMedium') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('MontserratRegular/MontserratRegular.eot');
  src: url('MontserratRegular/MontserratRegular.eot') format('embedded-opentype'),
  url('MontserratRegular/MontserratRegular.woff2') format('woff2'),
  url('MontserratRegular/MontserratRegular.woff') format('woff'),
  url('MontserratRegular/MontserratRegular.ttf') format('truetype'),
  url('MontserratRegular/MontserratRegular.svg#MontserratRegular') format('svg');
}
