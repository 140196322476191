.burger-icon {
  width: 48px;
  height: 48px;
  background: $burgerIcon center center no-repeat;
}

.need-call {
  position: fixed;
  bottom: 150px;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  background: rgba(242, 26, 26, 0.3);
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;

  .need-call-in {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    background: rgba(242, 26, 26, 0.4);
    border-radius: 50%;

    i {
      color: white;
    }
  }
}
