.our-courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  margin: 0;
}

.our-courses-mobile__row {
  display: flex;
  flex-wrap: wrap;
  margin: 161px -15px 0;
  padding-top: 50px;
  position: relative;

  > span {
    position: absolute;
    top: 0;
    width: calc(100% / 3 - 30px);
    height: calc(100% + 35px);
    background: white;
    z-index: -1;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover .our-course__icon {
      fill: $buttonColor;
    }

    &:nth-of-type(1) {
      left: 15px
    }

    &:nth-of-type(2) {
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-of-type(3) {
      right: 15px;
    }
  }

  > div:not([class*="line-"]) {
    width: calc(100% / 3 - 30px);
    padding: 0 2vw 0 3vw;
    box-sizing: border-box;
  }

  > div[class*="line-"] {
    width: 100%;
  }

  > div {
    order: 10;

    &:not(.our-course__title) {
      margin: 0 15px;
    }

    &.our-course__title {
      margin: 68px 15px 13px;
      font-weight: 600;
    }

    &.our-course-skills {
      margin-top: 4px;
    }

    &.our-course-skill {
      margin-top: 6px;
    }

    &.our-course-whom {
      margin-top: 21px;
    }

    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 5;
    }

    &:nth-of-type(3) {
      order: 9;
    }

    &:nth-of-type(4) {
      order: 13;
    }

    &:nth-of-type(5) {
      order: 16;
    }

    &:nth-of-type(6) {
      order: 20;
    }

    &:nth-of-type(7) {
      order: 2;
    }

    &:nth-of-type(8) {
      order: 6;
    }

    &:nth-of-type(9) {
      order: 10;
    }

    &:nth-of-type(10) {
      order: 14;
    }

    &:nth-of-type(11) {
      order: 17;
    }

    &:nth-of-type(12) {
      order: 21;
    }

    &:nth-of-type(13) {
      order: 3;
    }

    &:nth-of-type(14) {
      order: 7;
    }

    &:nth-of-type(15) {
      order: 11;
    }

    &:nth-of-type(16) {
      order: 15;
    }

    &:nth-of-type(17) {
      order: 18;
    }

    &:nth-of-type(18) {
      order: 22;
    }

    &:nth-of-type(19) {
      order: 4;
    }

    &:nth-of-type(20) {
      order: 8;
    }

    &:nth-of-type(21) {
      order: 12;
    }

    &:nth-of-type(22) {
      order: 19;
    }
  }
}

.our-course {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  border-radius: 30px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  padding: 30px 20px 17px 26px;
  box-sizing: border-box;
  margin-top: 30px;
  cursor: pointer;

  @include sm() {
    padding: 5px 30px 46px 60px;
  }

  &:hover .our-course__icon {
    fill: $buttonColor;
  }

  @include md() {
    margin-top: 139px;
    width: 32%;
  }

  svg {
    display: none;

    @include md() {
      display: block;
      margin: -105px auto 0;
    }
  }
}

.our-course__icon {
  transition: fill 0.2s;
  fill: #73C9FF;
}

.our-course__title {
  @include font($brandColor, 16px, 20px);
  text-transform: uppercase;
  word-break: break-word;

  @include sm() {
    @include font($brandColor, 18px, 24px);
  }

  @include md() {
    @include font($brandColor, 14px, 16px);
  }

  @include md-high() {
    @include font($brandColor, 18px, 24px);
  }

  &_white {
    @include font($brandColor, 16px, 20px);
    text-transform: uppercase;
    color: white;
    margin: 0;

    @include sm() {
      @include font($brandColor, 22px, 27px);
    }
  }
}

.our-course-wrapper {
  .our-course-whom:first-child {
    margin-top: 0;
  }
}

.our-course-whom {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 27px;

  i {
    color: #0081C4;
  }
}

.our-course-whom__title {
  @include font($brandColorDark, 20px, 24px);
  margin: 0 0 0 10px;

  @include md() {
    @include font($brandColorDark, 16px, 20px);
  }

  @include md-high() {
    @include font($brandColorDark, 24px, 29px);
  }
}

.our-course-descriptions {
  margin: 5px 0 0;
}

.our-course__description {
  @include font($mainTextColor, 16px, 18px);
  margin: 0 0 0;

  @include sm() {
    @include font($mainTextColor, 16px, 20px);
  }

  @include md() {
    @include font($mainTextColor, 14px, 16px);
  }

  @include md-high() {
    @include font($mainTextColor, 16px, 20px);
  }
}

.our-course-skills {
  margin-top: 9px;

  i {
    color: $buttonColor;
    margin-right: 5px;
  }
}

.our-course-skill {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.our-course-skill__more-info {
  @include font($brandColorDark, 18px, 22px);
  text-decoration-line: underline;
  font-weight: 500;
  margin: 45px 0 0;
  cursor: pointer;
  text-align: center;

  @include md() {
    margin: 52px 0 0;
  }
}

.our-course-skill__text {
  @include font($mainTextColor, 16px, 20px);
  margin: 0;

  @include md() {
    @include font($mainTextColor, 14px, 16px);
  }

  @include md-high() {
    @include font($mainTextColor, 16px, 20px);
  }
}
