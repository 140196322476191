@mixin font($color, $size, $line, $family: false) {
  color: #{$color};
  font-size: #{$size};
  line-height: #{$line};

  @if $family == false {
    font-family: $mainFont;
  } @else {
    font-family: #{$family};
  }
  @content;
}

@mixin media($value) {
  @media screen and (min-width: $value) {
    @content;
  }
}

@mixin sm($value: 576px) {
  @include media($value) {
    @content;
  }
}

@mixin md($value: 1024px) {
  @include media($value) {
    @content;
  }
}

@mixin md-high($value: 1310px) {
  @include media($value) {
    @content;
  }
}

@mixin lg($value: 1920px) {
  @include media($value) {
    @content;
  }
}
