.container-page {
  position: relative;
  padding: 20px 20px;

  @include sm() {
    padding: 20px 35px;
  }

  @include md() {
    padding: 20px 13.3%;
  }
}

.container-page__title {
  @include font($brandColorDark, 28px, 32px);
  margin: 22px 0 0;
  text-align: center;

  @include sm() {
    @include font($brandColorDark, 36px, 44px);
    margin: 17px 0 0;
  }

  @include md() {
    @include font($brandColorDark, 42px, 51px);
    margin: 52px 0 0;
    text-align: left;
  }
}
