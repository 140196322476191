.header {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 26px 20px;

  @include sm() {
    padding: 20px 35px;
  }

  @include md() {
    padding: 19px 13.3% 18px;
  }
}

.header-block-logo {
  width: 60px;

  @include sm() {
    width: 176px;
  }
}

.header-block-logo__mobile {
  @include sm() {
    display: none;
  }
}

.header-block-logo__light {
  display: none;

  @include sm() {
    display: block;
  }
}

.header-block {
  width: 37%;
  display: none;

  @include md() {
    display: block;
  }
}

.header-block-city-select {
  width: 170px;
}

.header-block-city-list {
  width: calc(100% - 176px - 37% - 160px);
  display: none;

  @include md() {
    display: block;
  }
}

.header-block-text {
  @include font($mainTextColor, 16px, 20px);
  margin: 0;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.header-cities {
  display: flex;
  flex-direction: column;
}

.header-block-address {
  @include font($mainTextColor, 16px, 20px);
  margin: 0;
  font-weight: 400;
  text-align: right;
  text-decoration: none;
}

.header-block-address__link {
  &:hover {
    text-decoration: underline;
  }
}

.header-block-address_gray {
  color: #7f7f7f;
}

.header-block-numbers {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    display: none;
  }

  @include lg() {
    flex-direction: row;

    p {
      display: block;
    }
  }
}
