.course-help {
  position: relative;
  width: 100%;
  margin-top: 15px;
  box-sizing: border-box;
  background: $brandColor;
  padding-top: 67.5%;

  @include md() {
    margin-top: 100px;
    padding-top: 30.5%;
    background: url("../../images/desc.png") calc(100% - 25px) center no-repeat, url("../../images/course-help.jpg") left no-repeat $brandColor;
    background-size: 263px, cover, 100%;
  }

  @include md-high() {
    background: url("../../images/desc.png") 90% center no-repeat, url("../../images/course-help.jpg") left no-repeat $brandColor;
    background-size: 263px, cover, 100%;
  }
}

.course-help__image {
  width: 100%;
  visibility: hidden;

  @include md() {
    visibility: visible;
  }
}

.icon-desc {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 6%;
  transform: translateY(-50%);
  display: none;

  @include md-high() {
    display: block;
  }
}

.course-help-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  width: 73.4%;

  @include md-high() {
    width: 48%;
  }

  .main-button {
    margin-top: 36px;
    padding: 11px 48px;
  }
}

.course-help-form__title {
  @include font(white, 18px, 20px);
  margin: 0;
  text-align: center;

  @include sm() {
    @include font(white, 24px, 29px);
  }

  @include md() {
    width: 55%;
    @include font(white, 30px, 38px);
  }

  @include md-high() {
    width: 100%;
    @include font(white, 48px, 59px);
  }
}

.course-help-form__description {
  @include font(white, 16px, 18px);
  margin: 17px 0 0;

  @include sm() {
    @include font(white, 18px, 22px);
  }

  @include md() {
    @include font(white, 24px, 28px);
  }

  @include md-high() {
    @include font(white, 36px, 44px);
  }
}
