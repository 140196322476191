.academy-life-wrapper {
  display: none;

  @include md() {
    display: block;
  }
}

.academy-life {
  display: grid;
  grid-template-columns: repeat(47, 1fr);
  grid-template-rows: repeat(15, 27px);
  margin-top: 39px;

  @include md() {
    grid-template-rows: repeat(15, 29px);
  }

  @include md-high() {
    grid-template-rows: repeat(15, 37px);
  }

  @include lg() {
    grid-template-rows: repeat(15, 51px);
  }

  .box {
    height: 100%;
    width: 100%;
    border-radius: 30px;
  }

  .academy1 {
    background: url('../../images/academy/academy1.jpg');
    background-size: 100% 100%;
    grid-column: 1 / 16;
    grid-row: 1 / 6;
  }

  .academy2 {
    background: url('../../images/academy/academy2.jpg');
    background-size: 100% 100%;
    grid-column: 17 / 28;
    grid-row: 1 / 6;
  }

  .academy3 {
    background: url('../../images/academy/academy3.jpg');
    background-size: 100% 100%;
    grid-column: 29 / 48;
    grid-row: 1 / 6;
  }

  .academy4 {
    background: url('../../images/academy/academy4.jpg');
    background-size: 100% 100%;
    grid-column: 1 / 24;
    grid-row: 7 / 16;
  }

  .academy5 {
    background: url('../../images/academy/academy5.jpg');
    background-size: 100% 100%;
    grid-column: 25 / 36;
    grid-row: 7 / 11;
  }

  .academy6 {
    background: url('../../images/academy/academy6.jpg');
    background-size: 100% 100%;
    grid-column: 25 / 36;
    grid-row: 12 / 16;
  }

  .academy7 {
    background: url('../../images/academy/academy7.jpg');
    background-size: 100% 100%;
    grid-column: 37 / 48;
    grid-row: 7 / 16;
  }
}


