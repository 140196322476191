.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0;
  background: #00315E;
  padding: 26px 20px;

  @include sm() {
    padding: 20px 35px;
    justify-content: space-between;
  }

  @include md() {
    margin-top: 104px;
    padding: 20px 13.3%;
    flex-wrap: nowrap;
  }
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0 0 25px;

  @include sm() {
    margin: 0 0 0 14%;
  }

  .footer-icons__icon:first-child {
    margin-left: 0;
  }
}

.footer-icons-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brandColor;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 13px;
  cursor: pointer;
  text-decoration: none;

  i {
    color: #00315E;
  }
}

.footer-cities {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
  width: 100%;

  @include sm() {
    margin: 24px 0 0;
  }

  @include md() {
    margin: 0;
    width: auto;
  }
}

.footer-block-address {
  @include font(white, 14px, 20px);
  margin: 0;
  font-weight: 400;
  text-align: center;
  text-decoration: none;

  @include md() {
    text-align: right;
  }
}

.footer-block-address__link {
  &:hover {
    text-decoration: underline;
  }
}

.footer-block-numbers {
  display: flex;
  flex-direction: column; 
   justify-content: center;

  @include md() {
    justify-content: flex-end;
  }
}
