.bitcom-solution {
  display: none;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0;
  text-decoration: none;

  @include md() {
    display: flex;
  }
}

.bitcom-solution__text {
  @include font(#767F84, 12px, 15px);
}

.bitcom-solution__logo {
  margin-left: 10px;
}

.bitcom-solution-mobile {
  display: flex;

  @include md() {
    display: none;
  }
}
